<template>
    <GmapMap v-if="geo_address" :center="geo_address" :zoom="12" class="map-container" style="height: 200px; width: 500px;" >
        <GmapMarker
            :position="geo_address"
            :clickable="true"
            :draggable="true"
            :icon="{url: require('../../../assets/imgicons/ICONOS SOSRecurso 1@2x.png'), scaledSize: { width: 36, height: 47 }}"
        />
    </GmapMap>
</template>

<script>
export default {
    props: ['geo_address'],
    data () {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
</style>