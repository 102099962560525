<template>
	<div class="camera-single">
		<div class="camera-video">
			<CameraConfig v-if="configDevice && isMainCamera" v-show="$store.state.mainCameraConfig"/>
			<p v-if="cameraData?.address && !isMainCamera" v-c-tooltip="{content: cameraData?.address, placement: 'bottom-start'}" class="address">{{ cameraData?.address }}</p>
			<video
				v-show="showVideo"
				autoplay
				:muted="!(isMainCamera && audioStatus)"
				:srcObject.prop="remoteStream"
				data-setup='{"fluid": true, "liveui": true}'
				:style="isMainCamera ? 'position: absolute;z-index: 99;':''"
				class="video-js"></video>
			<div v-show="!showVideo" class="loading-video">
				<CSpinner
					grow
					style="width:40px;height:40px;color:#bcbcbc"
				/>
				<p style="font-weight: bold; font-size: 12px; margin-bottom: 0; margin-top: 4px">{{ loadingText }}</p>
			</div>
			<div v-if="cameraData?.address && isMainCamera" v-show="isMainCamera" style="position: absolute; bottom: 0; right: 0; z-index: 123;">
				<div @click="collapseMap = !collapseMap" class="d-flex" style="width:500px;">
				<CButton color="primary" style="width:30px;height: 25px;display: flex;align-items: center;justify-content: center;"><font-awesome-icon
					:icon="collapseMap ? 'chevron-down' : 'chevron-up'"
				/></CButton>
				<p v-c-tooltip="{content: cameraData?.address, placement: 'top'}" class="address" style=" padding-left: 4px;width: 100%;position:relative;margin-bottom: 0; background: rgba(60, 75, 100);">{{ cameraData?.address }}</p>
				</div>
				<CCollapse :show="collapseMap">
					<Map :geo_address="cameraData?.geo_address"/>
				</CCollapse>
			</div>
		</div>
		<CameraButtons
			:main="isMainCamera"
			:cameraData="cameraData"
			:localStream="localStream"
			:localAudioStream="localAudioStream"
			:refreshCamera="refreshCamera"
			:cameraConnected="cameraConnected"
			:peerWithAudio="peerWithAudio"
			:startAudioConnection="startAudioConnection"
			:setAudioStatus="setAudioStatus" />

	</div>
</template>

<script>
import CameraButtons from './CameraButtons'
import CameraConfig from './CameraConfig'
import Map from './Map'
import 'video.js/dist/video-js.css'
import { login } from '../../../api/user'

export default {
	name: 'CameraView',
	components: {CameraButtons, CameraConfig, Map},
	props: ['cameraData', 'showCameraViewComponent', 'ptzWithKeys', 'socket', 'main', 'handlerSetMainCamera', 'cuadrante_id'],
	computed: {
		isMainCamera(){
			return this.$store.state.mainCamera && this.cameraData.deviceId == this.$store.state.mainCamera
		},
		showVideo(){
			return !this.loading && this.remoteStream && this.cameraConnected
		},
	},
	watch: {
		isMainCamera(newValue, oldValue){
			if(oldValue && this.peerWithAudio){
				this.refreshCamera(false)
			}
		},
		'$store.state.mainCamera': function() {
			this.mapOpenedDefault = true;
			this.collapseMap = true;
			setTimeout(() => {
				if (this.mapOpenedDefault) {
					this.collapseMap = false;
					this.mapOpenedDefault = false;
				}
			}, 10000);
		}
	},
	data () {
		return{
			loading: true,
			loadingText: 'Iniciando Cámara',
			cameraStream: null,
			videojsOptions: {},
			player: null,
			reloaderCount: 0,
			reloads: 0,
			socketUrl: 'https://devapis.anubys.cl:3000',
			localStream: null,
			localAudioStream: null,
			remoteStream: null,
			remoteAudioStream: null,
			audio: false,
			pc: null,
			pcAudio: null,
			alarm:false,
			commands: [],
			cameraConnected: false,
			peerWithAudio: false,
			audioStatus: false,
			configDevice: true,
			collapseMap: true,
			mapOpenedDefault: true
    }
	},
	mounted(){
		if(this.cameraData){
			this.setListeners()
			this.startConnection()
		}
	},
  methods: {
		refreshCamera(peerWithAudio){
			this.pc.close();
			this.pc = null
			this.localStream = null
			this.socket.emit("camera_disconnect", this.cameraData?.deviceId)
			if(typeof peerWithAudio === 'boolean'){
				this.peerWithAudio = peerWithAudio
			}

			setTimeout(() => {
				this.startConnection()
			}, 500);
		},
		setMainVideo() {
			this.handlerSetMainCamera(this.cameraData)
			this.$store.commit('setMainCamera', null)
			this.$store.commit('setMainCamera', this.cameraData)
		},
		disableVideo() {
			if (this.localStream) {
				const tracks = this.localStream.getVideoTracks();
				tracks.forEach(track => {
					track.enabled = false
				});
			}
		},
		setAudioStatus(status){
			this.audioStatus = status
		},
		setListeners() {
			this.socket.on("answer", (answer) => {
					if (this.cameraData.deviceId == answer.deviceId) {
						if(answer.connection_type == 'video'){
							this.pc.setRemoteDescription( new RTCSessionDescription(answer.answer) );
						}
						else if(answer.connection_type == 'audio'){
							this.pcAudio.setRemoteDescription( new RTCSessionDescription(answer.answer) );
						}
					}
				});

				this.socket.on("candidate", (candidate) => {
					if (this.cameraData.deviceId == candidate.deviceId) {
						if(candidate.connection_type == 'video'){
							this.pc.addIceCandidate(new RTCIceCandidate(candidate.candidate));
						}
						else if(candidate.connection_type == 'audio'){
							this.pcAudio.addIceCandidate(new RTCIceCandidate(candidate.candidate));
						}
					}
				});

				this.socket.on("disconnect", () => {
					if(this.pc){
						this.pc.close();
						this.pc = null;
					}
					if(this.pcAudio){
						this.pcAudio.close();
						this.pcAudio = null;
					}
				});
		},
		startConnection() {
			this.socket.emit("setCameraConnection", {deviceId: this.cameraData?.deviceId, cuadranteId: this.cuadrante_id}, (response) => {
				console.log('setCameraConnection', response)
			})

			setTimeout(() => {
				!this.cameraConnected && this.refreshCamera()
			}, 10000);
			
			this.cameraConnected = false
			this.loadingText = this.peerWithAudio ? 'Iniciando conexión con Audio':'Iniciando Cámara'

			this.socket.emit("joinRoomWithConfig", [this.cameraData]);
			let pc = new RTCPeerConnection({ iceServers: this.cameraData.ices });
			this.pc = pc
			navigator
				.mediaDevices
				.getUserMedia({ video: !this.peerWithAudio, audio: this.peerWithAudio })
				.then((stream) => {
					this.localStream = stream;
					this.pc.addStream(this.localStream);
					this.disableVideo()

					this.pc.createOffer({'offerToReceiveVideo':true, 'offerToReceiveAudio': this.peerWithAudio})
					.then((offer) => this.pc.setLocalDescription(offer))
					.then(() => {
					if(this.pc.localDescription){
							this.socket.emit("offer", {
								device_id: this.cameraData?.deviceId,
								connection_type: 'video',
								offer: this.pc.localDescription,
							})
						}
					});

					this.pc.ontrack = (event) => { this.remoteStream = event.streams[0]};

					this.pc.onicecandidate = (event) => {
						if (event.candidate) {
							this.socket.emit("candidate", {
								device_id: this.cameraData?.deviceId,
								connection_type: 'video',
								candidate: event.candidate,
							});
						}
					};

					this.pc.addEventListener('connectionstatechange', () => {
						if (this.pc.connectionState === 'connecting') {
							this.loadingText = 'Estableciendo conexión con Cámara'
						}
						if (this.pc.connectionState === 'connected') {
							this.loadingText = 'Cámara Conectada'
							this.cameraConnected = true
							this.loading = false

							if (this.$route.query.camera_id && this.$route.query.camera_id == this.cameraData.deviceId) {
								this.$store.commit('setMainCamera', this.$route.query.camera_id)
							}
						}
						
						if (this.pc.connectionState === 'failed') {
							this.refreshCamera()
						}
					})
				})
				.catch((error) => {
					console.error("Error accessing media devices.", error);
				});
		},
		startAudioConnection(){
			this.pc.close()
			this.pc = null
			this.localStream = null
			this.socket.emit("camera_disconnect", this.cameraData?.deviceId)
			this.peerWithAudio = true
			this.startConnection()
		}
	},
}
</script>

<style scoped>
	.camera-video{
		background-color: rgb(210, 210, 210);
		width: 100%;
		height: 100%;
		flex: 1;
		object-fit: contain;
		position: relative;
	}
	.camera-video video{
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.camera-single{
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100%;
		height: 100%;
	}
	.loading-video{
		width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
		flex-direction: column;
	}
	.address {
		position: absolute;
		z-index: 123;
		right: 0;
		color: #FFFF;
		background: rgba(60, 75, 100, 0.5);
		padding: 2px;

		width: 50%;
  		white-space: nowrap;
  		overflow: hidden;
  		text-overflow: ellipsis;
	}
</style>