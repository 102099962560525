import Vue from 'vue'
import Vuex from 'vuex'
import { getStatusCamera } from '@/api/cameras'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userData: null,
    monitorPanelShow: true,
    showVideo: true,
    showBotones: false,
    showTracking: true,
    alarmSound: localStorage.alarmSound && JSON.parse(localStorage.alarmSound) ? true : false,
    autoVideoOnAlert: localStorage.autoVideoOnAlert && JSON.parse(localStorage.autoVideoOnAlert) ? true : false,
    autoVideoRecordOnAlert: localStorage.autoVideoRecordOnAlert && JSON.parse(localStorage.autoVideoRecordOnAlert) ? true : false,
    toCallData: null,
    showUserModalSearch: false,
    reportData: null,
    grillaMonitoreo: true,
    monitoreoGrillaCounter: 0,
    soloTrackingUser: null,
    contentInfoUser: null,
    trackingInBigMap: null,
    mapCenter: {center: { lat: -33.4373063, lng: -70.634297 }, zoom:  12},
    usersInMonitoreo: [],
    primaryDB: null,
    monitoreoDB: null,
    trackingDB: null,
    uploadsInProgress: [],
		alertsData: [],
    videoCallData: {
      status: false,
      alertID: null,
      geoUserAlert: null,
      fullView: true,
      deviceID: null,
      calledUserID: null,
      socketUrl: null,
      socketRoom: null,
      videocallID: null,
      hideVideo: false,
      silent: true
    },
		videocallSoloConnected: false,
		videoCallDataMultiple: [],
		tuyaCameras: [],
    dashboardMarkers: 'general',
    modalTrackingFilter: false,
    trackingType: true,
    resetRtTracking:null,
    modalDeleteAllRtTracking: false,
    mainCamera: null,
		mainCameraStatus: [],
		loadingMainCameraStatus: false,
		mainCameraConfig: false,
    cuadranteMainCamera: null,
    updateCuadrante: null
  },
  mutations: {
    setMainCamera(state, mainCamera) {
      state.mainCamera = mainCamera

			state.mainCameraStatus = []
			state.loadingMainCameraStatus = false
			state.mainCameraConfig = false
    },
    setMainCameraStatus(state, mainCameraStatus) {
      state.mainCameraStatus = mainCameraStatus
    },
    setUpdateCuadranteCamera(state, updateCuadrante) {
      state.updateCuadrante = updateCuadrante
    },
    setLoadingMainCameraStatus(state, loadingMainCameraStatus) {
      state.loadingMainCameraStatus = loadingMainCameraStatus
    },
    setMainCameraConfig(state, mainCameraConfig) {
      state.mainCameraConfig = mainCameraConfig
    },
    setDataCuadranteCamera(state, cuadranteMainCamera) {
      state.cuadranteMainCamera = cuadranteMainCamera
    },
    setModalDeleteAllRtTracking (state, modalDeleteAllRtTracking) {
      state.modalDeleteAllRtTracking = modalDeleteAllRtTracking
    },
    setResetRtTracking (state, resetRtTracking) {
      state.resetRtTracking = resetRtTracking
    },
    setTrackingType (state, trackingType) {
      state.trackingType = trackingType
    },
    setDashboardMarkers (state, dashboardMarkers) {
      state.dashboardMarkers = dashboardMarkers
    },
    setModalTrackingFilter (state, modalTrackingFilter) {
      state.modalTrackingFilter = modalTrackingFilter
    },
    setUserData (state, user) {
      state.userData = user
    },
    setAlertsData (state, alertsData) {
      state.alertsData = alertsData
    },
    setPanelShow (state, value) {
      state.monitorPanelShow = value
    },
    setShowVideo (state, value) {
      state.showVideo = value
    },
    setShowBotones (state, value) {
      state.showBotones = value
    },
    setShowTracking (state, value) {
      state.showTracking = value
    },
    setAlarmSound (state, value) {
      localStorage.alarmSound = value;
      state.alarmSound = value
    },
    setCallData (state,value) {
      state.toCallData = value
    },
    setShowUserModalSearch(state,value){
      state.showUserModalSearch = value
    },
    setReportECGData(state,value){
      state.reportData = value
    },
    toogleGrilla(state,value){
      state.grillaMonitoreo = value
    },
    setMonitoreoGrillaCounter(state,value){
      state.monitoreoGrillaCounter = value
    },
    setSoloTrackingUser (state,value) {
      state.soloTrackingUser = value
    },
    setTrackingInBigMap (state,value) {
      state.trackingInBigMap = value
    },
    setMapCenter (state,value) {
      state.mapCenter = value
    },
    setContentInfoUser (state,value) {
      state.contentInfoUser = value
    },
    setUsersInMonitoreo (state,value) {
      state.usersInMonitoreo = value
    },
    setPrimaryDB (state,value) {
      state.primaryDB = value
    },
    setMonitoreoDB (state,value) {
      state.monitoreoDB = value
    },
    setTrackingDB (state,value) {
      state.trackingDB = value
    },
    setUploadsInProgress (state, value) {
      state.uploadsInProgress = value
    },
    setAutoVideoOnAlert (state, value) {
      localStorage.autoVideoOnAlert = value;
      state.autoVideoOnAlert = value
    },
    setAutoVideoRecordOnAlert (state, value) {
      localStorage.autoVideoRecordOnAlert = value;
      state.autoVideoRecordOnAlert = value
    },
    setVideoCallData (state, value) {
      state.videoCallData = value
    },
    setVideocallSoloConnected (state, value) {
      state.videocallSoloConnected = value
    },
		setTuyaCameras (state, value) {
      state.tuyaCameras = value
    },
		setVideoCallDataMultiple (state, value) {
      state.videoCallDataMultiple = value
    },
  },
  actions: {
    getMainCameraStatus({ commit, state }, cameraData) {
			if(!state.loadingMainCameraStatus){
				commit('setLoadingMainCameraStatus', true)
				getStatusCamera(cameraData).then(res => {
					commit('setMainCameraStatus', res.commands)
					commit('setLoadingMainCameraStatus', false)
				}).catch(e => {
					console.log(e);
					commit('setLoadingMainCameraStatus', false)
				})
			}
    },
  },
  getters: {
    toCallData: state => {
      return state.toCallData
    },
    userData: state => {
      return state.userData
    },    
    soloTrackingUser: state => {
      return state.soloTrackingUser
    },
    trackingInBigMap: state => {
      return state.trackingInBigMap
    },
    reportData: state => {
      return state.reportData
    },
		mainCamera: state => {
      return state.mainCamera
    },
  }
})

export default store