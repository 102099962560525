import {endpoints} from './endpoints'
import request from '../helpers/request'

export function cameraList(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.cameraList,
      method: 'post',
	  data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function getTuyaCamera(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getTuyaCamera,
      method: 'post',
	  data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getTuyaCameraVideoInfo(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getTuyaCameraVideoInfo,
      method: 'post',
			data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}
export function cameraPtzMove(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.cameraPtzMove,
      method: 'post',
			data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function getStatusCamera(data){
	return new Promise((resolve, reject) => {
    request({
    	url: endpoints.getStatusCamera,
    	method: 'post',
		data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function commandTuyaDevice(data){
	return new Promise((resolve, reject) => {
    request({
    	url: endpoints.commandTuyaDevice,
    	method: 'post',
		data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function getCamerasGeo(){
	return new Promise((resolve, reject) => {
    request({
    	url: endpoints.getCamerasGeo,
    	method: 'get',
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function updateCuadranteCameraUser(data){
	return new Promise((resolve, reject) => {
    request({
    	url: endpoints.updateCuadranteCameraUser,
    	method: 'get',
		params: data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}