<template>
	<div class="config-device-container py-1">
		<div class="config-device-inner-container px-2 py-3">
			<div class="config-title-container">
				<p class="font-weight-bold mb-0">Configuración</p>
				<CButtonClose class="pb-1" @click="$store.commit('setMainCameraConfig', false)"/>
			</div>
			<hr class="my-1" />
			<div class="loading-configs" v-if="showLoading">
				<CSpinner
					grow
					style="width:40px;height:40px;color:#bcbcbc"
				/>
				<p style="font-weight: bold; font-size: 12px; margin-bottom: 0; margin-top: 4px">Cargando Configuraciones</p>
			</div>
			<div class="config-container" v-else>
				<div class="form-input-container direction-column my-3" v-if="availableCommand('ipc_siren_volume')">
					<p class="input-label mb-1">Volumen Sirena: <span style="font-size: 10px">{{ ipc_siren_volume }}%</span></p>
					<input v-model="ipc_siren_volume" type="range" class="input" min="0" max="100" step="1" @change="changeInput('ipc_siren_volume', ipc_siren_volume)">
				</div>
				<div class="form-input-container direction-column my-3" v-if="availableCommand('ipc_siren_duration')">
					<p class="input-label mb-1">Duración Sirena: <span style="font-size: 10px">{{ ipc_siren_duration }} segundos</span></p>
					<input v-model="ipc_siren_duration" type="range" class="input" min="10" max="600" step="10" @change="changeInput('ipc_siren_duration', ipc_siren_duration)">
				</div>
				<div class="form-input-container my-3" v-if="availableCommand('siren_sound')">
					<p class="input-label mb-0">Sonido Sirena:</p>
					<div class="input text-right">
						<v-select
							:clearable="false"
							:options="sounds"
							label="text"
							:reduce="data => data.value"
							v-model="siren_sound"
							style="min-width: 120px"
							@option:selected="changeInput('siren_sound', siren_sound)"></v-select>
					</div>
				</div>
				<div class="form-input-container my-3" v-if="availableCommand('motion_switch')">
					<p class="input-label mb-0">Sensor de Movimiento:</p>
					<div class="input text-right">
						<CSwitch
							size="sm"
							color="primary"
							shape = "pill"
							@update:checked="() => {
								motion_switch = !motion_switch
								changeInput('motion_switch', motion_switch)
							}"
							:checked="motion_switch"
						/>
					</div>
				</div>
				<div class="form-input-container my-3" v-if="availableCommand('motion_tracking')">
					<p class="input-label mb-1">Sensor de Movimiento (Seguimiento):</p>
					<div class="input text-right">
						<CSwitch
							size="sm"
							color="primary"
							shape = "pill"
							@update:checked="() => {
								motion_tracking = !motion_tracking
								changeInput('motion_tracking', motion_tracking)
							}"
							:checked="motion_tracking"
						/>
					</div>
				</div>
				<div class="form-input-container direction-column my-3" v-if="availableCommand('motion_sensitivity')">
					<p class="input-label mb-1">Sensor de Movimiento (Sensibilidad):</p>
					<CButtonGroup size="sm" class="input">
						<CButton size="sm" class="mx-0" color="dark" :variant="getVariantSensitivity(0)"
							@click="changeInput('motion_sensitivity', 0)">
							Bajo
						</CButton>
						<CButton size="sm" class="mx-0" color="dark" :variant="getVariantSensitivity(1)"
							@click="changeInput('motion_sensitivity', 1)">
							Medio
						</CButton>
						<CButton size="sm" class="mx-0" color="dark" :variant="getVariantSensitivity(2)"
							@click="changeInput('motion_sensitivity', 2)">
							Alto
						</CButton>
					</CButtonGroup>
				</div>

					<!-- select cuadrante cámara -->
	<div  v-if="$store.state.userData.role.user_role_id == 4 && $store.state.mainCamera">
		<p class="mb-0">Seleccione Cuadrante:</p>
		<div class="d-flex" style="gap: 4px;"> 
			<div style="width: 100%;">
			<v-select  :options="cuadrantes" label="nombre" :reduce="data => data.id" v-model="cuadrante_id" :clearable="false">
				<template slot="option" slot-scope="option">
					<div style="height: 50px;">
        			<p style="font-weight: 500; font-size: 16px;" class="pb-0 mb-0">{{ option.nombre }}</p>
        			<span v-for="comuna in option.comuna_id" :key="comuna.id" style="font-size: 12px;">{{ comuna.comuna }} - </span>
					</div>
    			</template>
			</v-select>
			</div>
		<CButton style="height: 35px; display:flex; justify-content: center; align-items: center;" v-if="!loading_btn" @click="updateCuadranteCameraUser" color="primary"><CIcon name="send" size="xl" class="icons-svg outline" style="width: 1rem!important;height: 1rem!important;"/></CButton>
		<CSpinner color="primary" v-else></CSpinner>
		</div>
	</div>

				<hr class="my-1" />
				<div class="form-input-container direction-column my-3" v-if="availableCommand('device_restart')">
					<p class="input-label mb-1">Reiniciar Dispositivo:</p>
					<p class="mb-0" style="font-size: 10px">Considera que esta opción desconectará la cámara por un momento mientras se reinicia el dispositivo</p>
					<CButton size="sm" class="mx-0" color="dark" variant="outline"
						@click="changeInput('device_restart', true)">
						Reiniciar
					</CButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import { commandTuyaDevice } from '@/api/cameras'
import { cameraList, updateCuadranteCameraUser } from '@/api/cameras'
import { getCuadrantes } from '@/api/cuadrantes'


export default {
	name: 'CameraConfig',
	components: {'v-select': vSelect},
	props: ['cameraData', 'commandsList'],
	computed: {
		commands(){
			return this.$store.state.mainCameraStatus
		},
		showLoading(){
			return this.$store.state.loadingMainCameraStatus && this.needToShowLoad
		}
	},
	watch: {
		commands(newValue){
			if(this.commands.length > 0){
				this.setStartValues()
			}
		},
	},
	data () {
		return{
			loading_btn: false,
			needToShowLoad: true,
			ipc_siren_volume: 70,
			ipc_siren_duration: 70,
			motion_switch: false,
			motion_tracking: false,
			motion_sensitivity: 0,
			siren_sound: 'item_1',
			sounds: [
				{text: 'Sonido 1', value: 'item_1'},
				{text: 'Sonido 2', value: 'item_2'},
				{text: 'Sonido 3', value: 'item_3'},
				{text: 'Sonido 4', value: 'item_4'},
				{text: 'Sonido 5', value: 'item_5'},
				{text: 'Sonido 6', value: 'item_6'}
			],
			cuadrantes: [],
			cuadrante_id: null,
			pagination: {
				page: 1,
				limit: 15,
				total: null,
			},
    }
	},
	mounted(){
		this.getCuadrantes()
	},
	methods: {
		getCuadrantes(){
      const data = {
        page: 1,
        limit: 9999999
      }
      const self = this
      getCuadrantes(data)
        .then(function(resp){
			self.cuadrantes = resp.data
			self.cuadrante_id = self.$store.state.cuadranteMainCamera.cuadrante_id
        })
        .catch(function(error){
          console.log(error)
        })
    },
		getVariantSensitivity(value){
			return this.motion_sensitivity == value ? '':'outline'
		},
		setStartValues(){
			this.commands.forEach(command => {
				if(this.hasOwnProperty(command.code)){
					this[command.code] = command.value
				}
			});
		},
		getStatusCamera() {
			this.$store.dispatch('getMainCameraStatus', {camera_id: this.$store.state.mainCamera})
		},
		availableCommand(command){
			return this.commands.some(obj => obj.code === command)
		},
		async changeInput(command, value){
			this.needToShowLoad = false
			await commandTuyaDevice({camera_id: this.$store.state.mainCamera, command, value})
			this.getStatusCamera()
		},
/* 		async getCameraList() {
			this.$store.commit('setTuyaCameras', [])
			this.$store.commit('setMainCamera', null)

			const data = {limit: this.pagination.limit, page:this.pagination.page, keep_ids: []}
				data.cuadrante_id = this.$store.state.userData?.info?.cuadrante_id

			const camaras = await cameraList(data)

			if (camaras.connections.length) {
				this.$store.commit('setTuyaCameras', camaras.connections)
			} 
		}, */
		updateCuadranteCameraUser() {
			const data = {user_id: this.$store.state.cuadranteMainCamera.user_id, cuadrante_id: this.cuadrante_id}
			this.loading_btn = true
			updateCuadranteCameraUser(data).then(res => {
				this.loading_btn = false
				this.$store.commit('setTuyaCameras', [])
				this.$store.commit('setMainCamera', null)
				this.$store.commit('setUpdateCuadranteCamera', this.cuadrante_id)
			})
		},
	}
}
</script>

<style scoped>
	.config-device-container{
		position: absolute;
		height: 100%;
		z-index: 999;
	}
	.config-device-inner-container{
		background-color: #f2f2f2;
		border: #adadad 1px solid;
		border-left: none;
		height: 100%;
		width: 100%;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		min-width: 300px;
		max-width: 350px;
		overflow: scroll;
	}
	.form-input-container{
		padding: 2px 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.form-input-container:hover{
		background-color: #e0e0e0;
	}
	.form-input-container.direction-column{
		flex-direction: column;
		align-items: normal;
	}
	.form-input-container .input-label{
		font-weight: bold;
		font-size: 12px;
	}
	.loading-configs{
		width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
		flex-direction: column;
	}
	.config-title-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
	}
</style>